@import './../../../../scss/theme-bootstrap';

.email-signup-block {
  padding: 10px;
  text-align: center;
  &__messages {
    text-align: $ldirection;
  }
  &__message {
    padding-top: 5px;
    &--error {
      color: $color-error;
    }
    &--success {
      color: inherit;
    }
  }
  &__fields {
    margin: 0 auto;
    display: block;
    position: relative;
    @include breakpoint($landscape-up) {
      display: flex;
    }
    &-inner {
      flex: 1;
    }
  }
  input[type='email'].email-signup-block__field {
    background: inherit;
    color: inherit;
    flex: 1;
    width: 100%;
    text-align: center;
    height: 50px;
    padding-top: 18px;
    &::placeholder {
      color: inherit;
    }
    &.error {
      border: 1px solid $color-error;
    }
    &:focus,
    &.non-empty {
      & + .label {
        font-size: 12px;
        line-height: 2;
      }
    }
  }
  &__label {
    display: block;
    pointer-events: none;
    overflow: hidden;
    line-height: 3.5;
    text-indent: 0;
    margin-top: -51px;
    cursor: text;
    height: 50px;
  }
  &__submit {
    text-transform: none;
    background: none;
    border: 0;
    color: inherit;
    margin: 5px;
    padding: 0;
    position: absolute;
    #{$rdirection}: 8px;
    font-size: 12px;
    top: 3px;
    @include breakpoint($landscape-up) {
      @include swap_direction(padding, 0 0 0 15px);
      top: auto;
      position: relative;
      #{$rdirection}: auto;
      margin: 0;
    }
    .icon--utility_email-signup {
      @include swap_direction(padding, 0 0 0 10px);
      display: block;
      font-size: 22px;
    }
  }
}
